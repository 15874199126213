import React from 'react';
import { Form as BootstrapForm, Button } from 'react-bootstrap';
import styled from 'styled-components';

import {
  HideContentResponsively,
  Stack,
  TextTitle as ThemedTextTitle,
} from 'theme';

import ConfirmIcon from 'images/assets/confirm-icon.svg';
import HorseBigSvg from 'images/assets/horse-big.svg';

const recipientEmail = 'mm@devgears.pro';

const FormImage = styled(HorseBigSvg)`
  position: absolute;
  right: -16px;
  bottom: 0;
  z-index: -2;
`;

const Form = styled(BootstrapForm)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: start;
  gap: 18px;
`;

const TextTitle = styled(ThemedTextTitle)`
  font-size: 30px;
  font-weight: 600;
  max-width: 645px;
  line-height: 32px;
  text-align: center;
  & span {
    color: #36b59a;
    white-space: nowrap;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
  &.full-width {
    max-width: 100%;
    @media (max-width: 400px) {
      span {
        white-space: normal;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  margin-top: 110px;
  margin-left: 16px;
  margin-right: 16px;
  padding-bottom: 100px;
  min-height: 443px;
  @media (max-width: 768px) {
    min-height: 595px;
    gap: 19px;
    margin-top: 50px;
  }
`;

const StyledStack = styled(Stack)`
  width: 413px;
  padding: 0;
  @media (max-width: 576px) {
    max-width: 413px;
    width: 100%;
  }
`;

const FormSentContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 34px;
  justify-content: center;
  align-items: center;
`;

export const ContactUsFormSection = () => {
  const [formSent, setFormSent] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [description, setDescription] = React.useState('');

  return (
    <Wrapper id="contact-us-form">
      <FormImage />
      {formSent ? (
        <FormSentContainer>
          <ConfirmIcon />
          <TextTitle className="full-width">
            Thank you for your application,
            <span> we will contact you soon</span>
          </TextTitle>
        </FormSentContainer>
      ) : (
        <>
          <TextTitle>
            Please fill out the form below and <span>we will contact</span> you
            as soon as possible!
          </TextTitle>
          <Form
            validated={validated}
            noValidate
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              setValidated(true);
              if (event.currentTarget.checkValidity()) {
                window.location.href = `mailto:${recipientEmail}?subject=Question&body=${description}`;
                setValidated(false);
                setFormSent(true);
              }
            }}
          >
            <StyledStack gap={0}>
              <Form.Control
                className="mb-3"
                type="text"
                size="lg"
                placeholder="Your name"
              />
              <Form.Group className="w-100 mb-3">
                <Form.Control
                  required
                  type="email"
                  size="lg"
                  pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                  placeholder="Your e-mail"
                />
                <Form.Control.Feedback type="invalid">
                  Correct e-mail is required
                </Form.Control.Feedback>
              </Form.Group>
              <HideContentResponsively style={{ width: '100%' }}>
                <StyledStack>
                  <Form.Control
                    className="mt-3"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    as="textarea"
                    placeholder="Describe your project"
                    rows={7}
                  />
                </StyledStack>
              </HideContentResponsively>
              <Button
                style={{ width: 196 }}
                size="lg"
                className="mt-4"
                type="submit"
              >
                Contact us
              </Button>
            </StyledStack>
            <HideContentResponsively breakpoint={767} direction="max">
              <StyledStack>
                <Form.Control
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                  as="textarea"
                  placeholder="Describe your project"
                  rows={9}
                />
              </StyledStack>
            </HideContentResponsively>
          </Form>
        </>
      )}
    </Wrapper>
  );
};
