import React from 'react';
import { Link } from 'gatsby';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

import { HideContentResponsively, Stack } from 'theme';

import CompanyLogoAlternative from 'images/assets/logo-header-alternative.svg';
import CompanyLogo from 'images/assets/logo-header.svg';
import MenuBurger from 'images/assets/menu-burger.svg';

const Logo = styled(CompanyLogo)`
  color: #2a3837;
`;

const LinksStack = styled(Stack)`
  a {
    &:hover {
      color: #36b59a;
    }
  }
`;

export const HeaderSection = () => {
  return (
    <>
      <HideContentResponsively>
        <Stack
          height={46}
          justifyContent="space-between"
          flexDirection="row"
          paddingTop="2px"
          paddingLeft="16px"
          paddingRight="4px"
          paddingBottom="3px"
        >
          <Link to="/">
            <CompanyLogoAlternative />
          </Link>
          <Dropdown>
            <Dropdown.Toggle className="removecaret">
              <MenuBurger />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#about-us">About us</Dropdown.Item>
              <Dropdown.Item href="#technology">Technologies</Dropdown.Item>
              <Dropdown.Item href="#wy-choose-us">Wy choose us</Dropdown.Item>
              <Dropdown.Item href="#services">Services</Dropdown.Item>
              <Dropdown.Item href="#education">Education</Dropdown.Item>
              <Dropdown.Item href="#contact-us">Contact us</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Stack>
      </HideContentResponsively>
      <HideContentResponsively breakpoint={767} direction="max">
        <Stack height={100} gap={2.6} flexDirection="row" alignItems="stretch">
          <LinksStack
            width={365}
            flexDirection="row"
            gap={4}
            justifyContent="end"
            alignItems="end"
            paddingBottom="20px"
          >
            <Link className="header-link" to="#about-us">
              About us
            </Link>
            <Link className="header-link" to="#technology">
              Technologies
            </Link>
            <Link className="header-link" to="#wy-choose-us">
              Wy choose us
            </Link>
          </LinksStack>
          <Link to="/">
            <Logo />
          </Link>
          <LinksStack
            flexDirection="row"
            gap={4}
            width={365}
            justifyContent="start"
            alignItems="end"
            paddingBottom="20px"
          >
            <Link className="header-link" to="#services">
              Services
            </Link>
            <Link className="header-link" to="#education">
              Education
            </Link>
            <Link className="header-link" to="#contact-us">
              Contact us
            </Link>
          </LinksStack>
        </Stack>
      </HideContentResponsively>
    </>
  );
};
