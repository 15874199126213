import React from 'react';
import type { HeadFC } from 'gatsby';

import {
  AboutUsSection,
  ContactUsFormSection,
  ContactUsSection,
  EducationSection,
  FooterSection,
  HeaderSection,
  MainSection,
  OurServicesSection,
  OurTechnologiesSection,
  WhyChooseUsSection,
} from 'components';

const IndexPage = () => {
  return (
    <>
      <HeaderSection />
      <MainSection />
      <AboutUsSection />
      <OurTechnologiesSection />
      <WhyChooseUsSection />
      <ContactUsSection />
      <OurServicesSection />
      <EducationSection />
      <ContactUsFormSection />
      <FooterSection />
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>DevGears Professionals</title>;
