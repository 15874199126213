import React from 'react';

export const useSliderTransition = (
  maxIndex: number
): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const timer = React.useRef<null | NodeJS.Timer>(null);

  React.useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      setSliderIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        return newIndex > maxIndex ? 0 : newIndex;
      });
    }, 10000);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [sliderIndex]);

  return [sliderIndex, setSliderIndex];
};
