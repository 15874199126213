import React from 'react';
import styled from 'styled-components';

import {
  Card,
  Line,
  PaddingContainer,
  Section,
  SectionDescription,
  SectionTitle,
  Stack,
} from 'theme';

const CardsItem = styled(Card)`
  text-align: left;
  padding: 30px;
  min-width: 413px;
  min-height: 207px;
  cursor: pointer;
  &:before {
    background: radial-gradient(#36b59a, #f2f2f2);
  }
  &:hover div:first-child {
    color: #36b59a;
  }
  @media (max-width: 576px) {
    min-width: 280px;
    min-height: 278px;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
`;

const Status = styled.div`
  margin-top: 3px;
  font-size: 15px;
  font-weight: 400;
  color: #c6cbde;
`;

const StyledSectionDescription = styled(SectionDescription)`
  max-width: 500px;
  width: 100%;
  wont-weight: 500;
  margin: 24px auto 42px;
`;

const StyledSection = styled(Section)`
  max-width: 1440px;
  width: 100%;
  padding-top: 150px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

const StyledStack = styled(Stack)`
  overflow-x: auto;
  margin: auto;
  max-width: min-content;
  justify-content: flex-start;
`;

export const EducationSection: React.FC = () => (
  <StyledSection height={'max-content'} position={'relative'}>
    <PaddingContainer>
      <SectionTitle id="education">
        DevGears <span>Education</span>
      </SectionTitle>
      <Line margin={'13px auto 0'} />
      <StyledSectionDescription>
        We give the opportunity to develop from the beginning positions to the
        high ones on real projects
      </StyledSectionDescription>
      <StyledStack flexDirection="row" gap={2.5}>
        <CardsItem>
          <Name>UI/UX Designer</Name>
          <Status>
            You can take away the main skills: user research, informatics
            architecture, sketching and prototyping. Upgrading ux and UI skills
            with the help of senior colleagues.
          </Status>
        </CardsItem>
        <CardsItem>
          <Name>QA Engineer</Name>
          <Status>
            Develop the knowledge of software QA methodologies, tools and
            processes. Will get an experience in writing clear, concise and
            comprehensive test plans and test cases.
          </Status>
        </CardsItem>
        <CardsItem>
          <Name>Front-end Developer</Name>
          <Status>
            We're following the main principles in software engineering,
            preparing teams to be ready to work with enterprise grade products
            and follow all best practices and processes to produce maintainable
            solutions.
          </Status>
        </CardsItem>
      </StyledStack>
    </PaddingContainer>
  </StyledSection>
);
