import React from 'react';
import { navigate } from 'gatsby';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import {
  Line,
  PaddingContainer,
  Section,
  SectionDescription,
  SectionTitle,
} from 'theme';

import BackgroundBlotImg from 'images/background-blot.png';

const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const StyledSectionDescription = styled(SectionDescription)`
  width: 660px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BgImg = styled.img`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 25%);
  z-index: -3;
  width: 100%;
  height: 600px;
  object-fit: fill;
  @media (max-width: 768px) {
    object-fit: cover;
  }
`;

export const ContactUsSection: React.FC = () => (
  <Section height={'175px'} position="relative">
    <PaddingContainer>
      <BgImg src={BackgroundBlotImg} alt="background blot" />
      <SectionTitle id="contact-us">
        Feel free to <span>contact us</span>
      </SectionTitle>
      <Line />
      <StyledSectionDescription margin="24px auto 0 auto">
        We offer professional product support, development, consulting services
        and launch support. Guarantees professionalism, confidentiality and full
        responsibility. Contact us for information and the manager will advise
        on current issues.
      </StyledSectionDescription>
      <ButtonWrapper>
        <Button
          style={{ width: 196 }}
          onClick={() => navigate('#contact-us-form')}
          size="lg"
        >
          Contact us
        </Button>
      </ButtonWrapper>
    </PaddingContainer>
  </Section>
);
