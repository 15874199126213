import styled from 'styled-components';

export const Stack = styled.section<{
  height?: number;
  width?: number;
  margin?: string;
  marginTop?: string;
  background?: string;
  padding?: number;
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  flexDirection?: 'column' | 'row';
  gap?: number;
  position?: string;
}>`
  height: ${({ height }) => (height !== undefined ? `${height}px` : 'auto')};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  margin: ${({ margin }) => (margin ? margin : 'inherit')};
  margin-top: ${({ marginTop }) => marginTop};
  padding: ${({ padding }) =>
    padding !== undefined ? `${padding * 8}px` : '8px'};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  background: ${({ background }) => background};
  display: ${({ display }) => display ?? 'flex'};
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'column'};
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  gap: ${({ gap }) => (gap !== undefined ? `${gap * 8}px` : '8px')};
  position: ${({ position }) => position ?? 'static'};
`;

export const TextTitle = styled.h1<{
  paddingTop?: string;
  color?: string;
  size?: number;
  fontFamily?: string;
  maxWidth?: number;
}>`
  margin: 0;
  font-weight: 600;
  padding-top: ${({ paddingTop }) => paddingTop};
  color: ${({ color }) => color ?? '#2a3837'};
  font-size: ${({ size }) => (size ? `${size}px` : 'inherit')};
  font-family: ${({ fontFamily }) => fontFamily ?? 'Metropolis, sans-serif'};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'initial')};
  line-height: 130%;
  span {
    color: #36b59a;
  }
`;

export const TextBlock = styled.p<{
  maxWidth?: number;
  fontWeight?: number;
  fontFamily?: string;
  size?: number;
  color?: string;
  lineHeight?: number;
  marginTop?: number;
  marginBottom?: number;
  textAlign?: string;
}>`
  margin: 0;
  margin-top: ${({ marginTop }) =>
    marginTop !== undefined ? `${marginTop * 8}px` : 'auto'};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom !== undefined ? `${marginBottom * 8}px` : 'auto'};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'auto')};
  font-weight: ${({ fontWeight }) =>
    fontWeight !== undefined ? `${fontWeight} !important` : 'inherit'};
  font-family: ${({ fontFamily }) => fontFamily ?? 'Metropolis, sans-serif'};
  font-size: ${({ size }) => (size ? `${size}px` : 'inherit')};
  color: ${({ color }) => color ?? '#252935'};
  line-height: ${({ lineHeight }) =>
    lineHeight !== undefined ? `${lineHeight}px` : 'inherit'};
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
`;

export const HideContentResponsively = styled.div<{
  breakpoint?: number;
  direction?: 'min' | 'max';
}>`
  display: block;
  ${({
    breakpoint = 768,
    direction = 'min',
  }) => `  @media (${direction}-width: ${breakpoint}px) {
    display: none;
  }`}
`;

export const Column = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap !== undefined ? `${gap * 8}px` : `0px`)};
`;

export const TitleContainer = styled(Stack)`
  font-size: 40px;
  @media (max-width: 576px) {
    font-size: 30px;
  }
`;

export const ResponsiveFontContainer = styled.div`
  font-size: 20px;
  line-height: 31px;
  @media (max-width: 576px) {
    line-height: 21px;
    font-size: 18px;
  }
`;

export const SectionTitle = styled.h2<{ paddingTop?: string }>`
  padding-top: ${({ paddingTop }) => paddingTop};
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  margin: 0;
  span {
    color: #36b59a;
  }
  @media (max-width: 576px) {
    font-size: 30px;
  }
`;

export const Section = styled.section<{
  height: string;
  width?: string;
  margin?: string;
  marginTop?: string;
  position?: string;
  paddingTop?: string;
  background?: string;
  padding?: string;
}>`
  min-height: ${({ height }) => height};
  width: ${({ width }) => (width ? width : '100%')};
  margin: ${({ margin }) => (margin ? margin : '0 auto ')};
  position: ${({ position }) => position};
  margin-top: ${({ marginTop }) => marginTop};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  background: ${({ background }) => background};
  overflow: visible;
`;

export const Line = styled.hr<{ margin?: string }>`
  width: 103px;
  border: 1px solid #c6cbde;
  margin: ${({ margin }) => (margin ? margin : '0 auto')};
`;

export const Card = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 27px 20px rgba(160, 220, 208, 0.03);
  border-radius: 30px;
  &:before {
    content: '';
    position: absolute;
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
    border-radius: 30px;
    background: radial-gradient(
      315.63% 155.15% at 56.95% -40.91%,
      #a7d5cb 24.11%,
      #87edd8 50.26%,
      #a0dcd0 100%
    );
    z-index: -1;
  }
`;

export const Button = styled.button<{
  width: string;
  height: string;
  margin?: string;
}>`
  display: block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => (margin ? margin : '60px auto')};
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  color: #ffffff;
  background: #36b59a;
  border-radius: 16px;
  border: none;
`;

export const SectionDescription = styled.p<{
  width?: string;
  margin?: string;
  fontWeight?: number;
}>`
  max-width: ${({ width }) => (width ? width : '100%')};
  width: 100%;
  margin: ${({ margin }) => margin};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  font-size: 20px;
  line-height: 30px;
  text-align: center;
`;

export const PaddingContainer = styled.div`
  margin-left: 16px;
  margin-right: 16px;
`;

export const Dots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  li {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: rgba(198, 203, 222, 1);
    margin-right: 15px;
    transition: 0.3s all;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &.active {
      width: 15px;
      height: 15px;
      background-color: #36b59a;
    }
  }
`;
