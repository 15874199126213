import React from 'react';

import {
  Line,
  ResponsiveFontContainer,
  Stack,
  TextBlock,
  TextTitle,
  TitleContainer,
} from 'theme';

export const AboutUsSection = () => {
  return (
    <Stack gap={3}>
      <div>
        <TitleContainer>
          <TextTitle id="about-us">
            <span>About</span> us
          </TextTitle>
        </TitleContainer>
        <Line />
      </div>
      <ResponsiveFontContainer>
        <TextBlock maxWidth={760}>
          We specialize on complex solutions for tech startups, web/mobile
          applications, MVP, infrastructure. Our distributed teams work by
          process that allows us to guarantee quality and results in ETAs.
          Members are high qualified engineers / designers / managers from
          Ukraine.
        </TextBlock>
      </ResponsiveFontContainer>
    </Stack>
  );
};
