import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Stack, TextBlock, HideContentResponsively } from 'theme';

import CompanyLogo from 'images/assets/logo-header.svg';
import UpperFooterBgImage from 'images/upper-footer-bg.png';
import footerBg from 'images/footer-bg.png';

const CONTENT = {
  aboutUs:
    'Solutions for Startups, maintenance, DevOps, QA. Distributed teams,\n' +
    '          clear process, predictable result',
};

const Logo = styled(CompanyLogo)`
  color: #fff;
`;

const FooterItems = styled.div`
  display: flex;
  color: #fff;
  justify-content: space-between;
  width: 100%;
  align-self: start;
  padding-left: 70px;
  p {
    width: 270px;
    height: 68px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
  }
  @media (max-width: 1010px) {
    padding-left: 20px;
  }
  @media (max-width: 805px) {
    gap: 60px;
    flex-wrap: wrap;
  }
  @media (max-width: 420px) {
    padding-left: 0;
  }
`;

const FooterItemsLinks = styled(Link)`
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  white-space: nowrap;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: #fff;
  &:hover {
    color: #36b59a;
  }
`;

const FooterLinksBlock = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  flex-wrap: wrap;
  gap: 8px;
  & a {
    &::after {
      content: '|' !important;
      margin-left: 8px;
    }
    &:last-of-type {
      &::after {
        content: unset !important;
      }
      margin-right: 0;
    }
  }
  @media (max-width: 420px) {
    margin-bottom: 50px;
  }
`;

const FooterSubLinks = styled(Link)`
  display: flex;
  text-decoration: none;
  color: #fff;
  list-style: none;
  margin-right: 0 15px 0 0;
  &:last-child {
    margin-right: 0;
    &::after {
      content: unset;
    }
  }
  &:hover {
    color: #36b59a;
  }
`;

const Caption = styled.h4`
  white-space: nowrap;
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 25px;
  line-height: 22.5px;
`;

const StyledWrapperStack = styled(Stack)`
  position: relative;
  background: url(${footerBg});
  background-position: top 0 right 0;
  justify-content: end;
  background-size: cover;
  height: 548px;
  gap: 84px;
  @media (max-width: 805px) {
    gap: 39px;
  }
  @media (max-width: 420px) {
    height: 750px;
  }
`;

const BackgroundBlot = styled.div`
  position: absolute;
  background: url(${UpperFooterBgImage}) 0 0/100% 100% no-repeat;
  background-size: cover;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  z-index: -3;
  width: 100%;
  height: 1250px;
  gap: 84px;
  @media (max-width: 805px) {
    height: 1400px;
    gap: 39px;
  }
  @media (max-width: 420px) {
    height: 2100px;
  }
`;

const StyledLinksStack = styled(Stack)`
  margin: 0;
  flex-grow: 1;
  flex-direction: row;
  gap: 104px;
  align-items: start;
  @media (max-width: 1010px) {
    width: 100%;
    gap: 8px;
    justify-content: space-between;
  }
  @media (max-width: 420px) {
    flex-wrap: wrap;
  }
`;

const StyledBusinessStack = styled(Stack)`
  margin: 0;
  padding: 0;
  flex-direction: row;
  gap: 60px;
  align-items: start;
  @media (max-width: 1010px) {
    width: 100%;
    gap: 8px;
    justify-content: space-around;
  }
  @media (max-width: 805px) {
    justify-content: start;
  }
  @media (max-width: 420px) {
    justify-content: space-between;
  }
`;

const StyledLogoStack = styled(Stack)`
  gap: 20px;
  margin: 0;
  width: 374px;
  flex-direction: row;
  @media (max-width: 420px) {
    width: 100%;
    min-width: 250px;
  }
`;

export const FooterSection: React.FC = () => (
  <StyledWrapperStack id="footer">
    <BackgroundBlot />
    <FooterItems>
      <StyledLogoStack>
        <Logo />
        <TextBlock fontWeight={100} color="#fff" textAlign="left">
          {CONTENT.aboutUs}
        </TextBlock>
      </StyledLogoStack>
      <StyledLinksStack>
        <StyledBusinessStack>
          <div>
            <Caption>For Business</Caption>
            <FooterItemsLinks to="#about-us">How we work?</FooterItemsLinks>
            <FooterItemsLinks to="#contact-us">Hire us</FooterItemsLinks>
          </div>
          <div>
            <Caption>Support</Caption>
            <FooterItemsLinks to="/">FAQ</FooterItemsLinks>
            <FooterItemsLinks to="#contact-us">Contact us</FooterItemsLinks>
          </div>
        </StyledBusinessStack>
        <div>
          <Caption>About Us</Caption>
          <FooterItemsLinks to="#services">Our services</FooterItemsLinks>
          <FooterItemsLinks to="#technology">Our technologies</FooterItemsLinks>
          <FooterItemsLinks to="#education">Our education</FooterItemsLinks>
        </div>
      </StyledLinksStack>
    </FooterItems>
    <FooterLinksBlock>
      <HideContentResponsively breakpoint={767} direction="max">
        <FooterSubLinks to="/">
          &copy; DevGears Professionals 2022
        </FooterSubLinks>
      </HideContentResponsively>
      <FooterSubLinks to="/">Terms</FooterSubLinks>
      <FooterSubLinks to="/">Privacy</FooterSubLinks>
      <FooterSubLinks to="/">English</FooterSubLinks>
      <FooterSubLinks to="/">Ukrainian</FooterSubLinks>
      <HideContentResponsively>
        <FooterSubLinks to="/">
          &copy; DevGears Professionals 2022
        </FooterSubLinks>
      </HideContentResponsively>
    </FooterLinksBlock>
  </StyledWrapperStack>
);
