import React from 'react';
import { navigate } from 'gatsby';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { Stack, TextBlock, TextTitle } from 'theme';
import { hooks } from 'common';

import ArrowRightIcon from 'images/assets/arrow-right.svg';
import ScrollDownIcon from 'images/assets/scroll-down-icon.svg';
import mainBg1 from 'images/main-section-bg-1.png';
import mainBg2 from 'images/main-section-bg-2.png';
import mainBg3 from 'images/main-section-bg-3.png';
import mainBg4 from 'images/main-section-bg-4.png';

const content = {
  bgImagesList: [mainBg1, mainBg2, mainBg3, mainBg4],
};

const ResponsiveMarginsContainer = styled.div<{ flexEnd: boolean }>`
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 31px;
  ${({ flexEnd }) =>
    flexEnd
      ? 'display: flex !important; width: 100% !important; justify-content: end; max-width: 100% !important;'
      : ''}
  @media (max-width: 576px) {
    max-width: 277px;
    font-size: 18px;
    margin-top: 16px;
  }
`;

const ButtonText = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

const ScrollDownAnimation = styled.div`
  @keyframes move-up-and-down {
    0% {
      transform: translate(-50%, -25%);
    }
    50% {
      transform: translate(-50%, 25%);
    }
    100% {
      transform: translate(-50%, -25%);
    }
  }
  animation-name: move-up-and-down;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  cursor: default;
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 400;
  font-size: 15px;
  line-height: 155%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 15%;
  left: 50%;
  @media (max-width: 320px) {
    display: none;
  }
`;

export const Dots = styled.ul<{ flexDirection?: string }>`
  position: absolute;
  right: 4%;
  top: 30%;
  list-style: none;
  padding: 0;
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: center;
  li {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: rgba(198, 203, 222, 1);
    transition: 0.3s all;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #36b59a;
    }
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

const TextTitleWithMargins = styled(TextTitle)<{
  marginLeft: string;
  textAlign: string;
}>`
  margin-left: ${({ marginLeft }) => marginLeft};
  text-align: ${({ textAlign }) => textAlign};
`;

const MainContentWithMargins = styled.div<{
  marginLeft: string;
  textAlign: string;
}>`
  margin-left: ${({ marginLeft }) => marginLeft};
  text-align: ${({ textAlign }) => textAlign};
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 874px;
  width: 100%;
  margin-bottom: 92px;
  @media (max-width: 576px) {
    min-height: 536px;
    margin-bottom: 50px;
  }
`;

const Slider = styled.div<{ translateX: number; width: number }>`
  transform: ${({ translateX }) => `translateX(${translateX}%)`};
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ width }) => `${width}%`};
  transition: transform 0.5s ease-in-out;
`;

const ImageContainer = styled.div<{
  background: string;
}>`
  width: 100%;
  background-image: ${({ background }) => `url(${background})`};
  position: relative;
  background-size: cover;
  background-position: bottom;
  padding: 16px;
  padding-top: 140px;
  padding-left: 81px;
  padding-right: 2.5%;
  min-height: 874px;
  font-size: 60px;
  height: 100%;
  width: 100%;
  @media (max-width: 576px) {
    font-size: 28px;
    padding-top: 90px;
    padding-left: 16px;
    min-height: 536px;
  }
`;

const isAlignedLeft = (index: number) =>
  index === 0 || index === content.bgImagesList.length - 1;

export const MainSection = () => {
  const [sliderIndex, setSliderIndex] = hooks.useSliderTransition(
    content.bgImagesList.length - 1
  );

  return (
    <Container>
      <Slider
        width={100 * content.bgImagesList.length}
        translateX={-(100 / content.bgImagesList.length) * sliderIndex}
      >
        {content.bgImagesList.map((image, index) => (
          <ImageContainer key={image} background={image}>
            <TextTitleWithMargins
              marginLeft={isAlignedLeft(index) ? 'initial' : 'auto'}
              textAlign={isAlignedLeft(index) ? 'start' : 'end'}
              maxWidth={711}
              color="#FFF"
            >
              Great solutions to bring your vision to life
            </TextTitleWithMargins>
            <MainContentWithMargins
              marginLeft={isAlignedLeft(index) ? 'initial' : 'auto'}
              textAlign={isAlignedLeft(index) ? 'start' : 'end'}
            >
              <ResponsiveMarginsContainer flexEnd={!isAlignedLeft(index)}>
                <TextBlock
                  fontWeight={100}
                  lineHeight={31}
                  textAlign={isAlignedLeft(index) ? 'start' : 'end'}
                  color="#FFF"
                  maxWidth={521}
                >
                  Our team can take care about your project including building
                  integration and deployment processes.
                </TextBlock>
              </ResponsiveMarginsContainer>
              <Button
                size="lg"
                variant="light"
                onClick={() => navigate('#contact-us')}
              >
                <Stack
                  alignItems="bottom"
                  flexDirection="row"
                  gap={4}
                  padding={0}
                >
                  <ButtonText>Contact us</ButtonText>
                  <ArrowRightIcon />
                </Stack>
              </Button>
            </MainContentWithMargins>
            <Dots flexDirection="column">
              {content.bgImagesList.map((image, index) => (
                <li
                  key={image}
                  className={index === sliderIndex ? 'active' : ''}
                  onClick={() => {
                    setSliderIndex(index);
                  }}
                />
              ))}
            </Dots>
            <ScrollDownAnimation>
              <span>Scroll down</span>
              <ScrollDownIcon />
            </ScrollDownAnimation>
          </ImageContainer>
        ))}
      </Slider>
    </Container>
  );
};
