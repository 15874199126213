import React from 'react';
import styled from 'styled-components';

import { Card, Dots, Line, Section, SectionTitle } from 'theme';
import { hooks } from 'common';

import whyChooseUsBg1 from 'images/why-choose-us-bg-1.png';
import whyChooseUsBg2 from 'images/why-choose-us-bg-2.png';
import whyChooseUsBg3 from 'images/why-choose-us-bg-3.png';

const content = {
  title: { main: 'Why choose ', span: 'us?' },
  dataList: [
    {
      cardCaption: 'Close-knit team',
      cardDescription:
        "We're all striving together to make progress and see it through. We rely on each other, collaborate, and have trust in one another.",
      cardImage: whyChooseUsBg1,
    },
    {
      cardCaption: 'A complex approach',
      cardDescription:
        'We are a full-cycle company, so the creation of a startup from scratch is controlled by specialists of various profiles. For the project, a team is allocated that will lead you from idea to success.',
      cardImage: whyChooseUsBg2,
    },
    {
      cardCaption: 'Result orientation',
      cardDescription:
        'DevGears Pro know that customer success is the best measure. We are not chasing money, we want to grow with you. A step-by-step plan developed together with the client helps us to realize the idea and achieve the goals.',
      cardImage: whyChooseUsBg3,
    },
  ],
};

export const SliderWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: 630px;
  min-height: 490px;
  left: 79px;
  top: 140px;
  padding: 48px 70px 48px 48px;
  &:before {
    background: radial-gradient(#36b59a, #f2f2f2);
  }
  span {
    margin-top: 51px;
    &:nth-child(3) {
      color: #36b59a;
      font-weight: 600;
    }
  }
  &::before {
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
  }
  @media (max-width: 1040px) {
    position: relative;
    top: 40px;
    left: 0;
    height: max-content;
    margin-left: 16px;
    margin-right: 16px;
  }
  @media (max-width: 576px) {
    padding: 30px 20px 30px 20px;
  }
`;

const Image = styled.img`
  transition: all 2s ease-out;
  position: absolute;
  top: 101px;
  right: 80px;
  width: 738px;
  height: 563px;
  z-index: -2;
  @media (max-width: 1040px) {
    display: none;
  }
`;

const Description = styled.p`
  text-align: left;
  margin: 22px 0 38px 0;
  font-size: 20px;
  line-height: 32px;
`;

const StyledSection = styled(Section)`
  width: 100%;
  position: relative;
  @media (max-width: 1040px) {
    display: flex;
    justify-content: center;
    min-height: 550px;
  }
  @media (max-width: 430px) {
    min-height: 700px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  @media (max-width: 576px) {
    & ul {
      align-self: center;
    }
  }
`;

export const WhyChooseUsSection: React.FC = () => {
  const [sliderIndex, setSliderIndex] = hooks.useSliderTransition(
    content.dataList.length - 1
  );

  return (
    <StyledSection height="764px">
      <SliderWrapper id="wy-choose-us">
        <SectionTitle>
          {content.title.main}
          <span>{content.title.span}</span>
        </SectionTitle>
        <Line margin={'15px 0 0'} />
        <span>{content.dataList[sliderIndex].cardCaption}</span>
        <DescriptionWrapper>
          <Description>
            {content.dataList[sliderIndex].cardDescription}
          </Description>
          <Dots>
            {content.dataList.map((data, index) => (
              <li
                key={data.cardCaption}
                className={index === sliderIndex ? 'active' : ''}
                onClick={() => setSliderIndex(index)}
              />
            ))}
          </Dots>
        </DescriptionWrapper>
      </SliderWrapper>
      {content.dataList.map((data, index) => (
        <Image
          className={index === sliderIndex ? 'visible' : 'hidden'}
          key={data.cardImage}
          src={data.cardImage}
          alt="card image"
        />
      ))}
    </StyledSection>
  );
};
