import React from 'react';
import styled from 'styled-components';

import {
  Card,
  Line,
  PaddingContainer,
  Section,
  SectionDescription,
  SectionTitle,
} from 'theme';

import GraduationHatIcon from 'images/assets/graduation-hat.svg';
import HandProgrammingIcon from 'images/assets/hand-programming.svg';
import LaptopPaintingIcon from 'images/assets/laptop-painting.svg';
import LaptopProgrammingIcon from 'images/assets/laptop-programming.svg';

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 100px 0 0 0;
  max-width: 1118px;
  width: 100%;
  border-radius: 30px;
  @media (max-width: 976px) {
    padding: 50px 0 0 0;
  }
`;

const CardsWrapper = styled.div`
  position: relative;
  margin-top: 63px;
  padding-top: 4px;
  padding-left: 4px;
  padding-bottom: 4px;
  display: grid;
  grid-template-columns: repeat(2, 402px);
  grid-template-rows: repeat(2, 471px);
  column-gap: 80px;
  row-gap: 32px;
  justify-content: center;
  z-index: 1;
  overflow-x: auto;
  overflow-y: hidden;
  @media (max-width: 976px) {
    justify-content: start;
    grid-template-columns: repeat(4, 320px);
    grid-template-rows: repeat(1, 421px);
  }
`;

const CardsItem = styled(Card)`
  padding: 0 44px 0 44px;
  &:before {
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
  }
  &:nth-child(1) {
    padding-top: 52px;
  }
  &:nth-child(2) {
    padding-top: 40px;
  }
  &:nth-child(3) {
    padding-top: 61px;
  }
  &:nth-child(4) {
    padding-top: 54px;
  }
  @media (max-width: 976px) {
    padding: 0 20px 0 20px;
    &:nth-child(1) {
      padding-top: 30px;
    }
    &:nth-child(2) {
      padding-top: 30px;
    }
    &:nth-child(3) {
      padding-top: 30px;
    }
    &:nth-child(4) {
      padding-top: 30px;
    }
  }
`;

const CardsItemTitle = styled.div`
  margin-top: 24px;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
`;

const CardsItemDescription = styled.p`
  margin-top: 16px;
  font-size: 20px;
  line-height: 30px;
  color: #535664;
`;

const StyledSection = styled(Section)`
  max-width: 1440px;
  min-height: max-content;
  @media (max-width: 976px) {
    width: 100%;
  }
`;

export const OurServicesSection: React.FC = () => (
  <StyledSection height={'1414px'}>
    <PaddingContainer>
      <Wrapper>
        <SectionTitle id="services">
          Our <span>Services</span>
        </SectionTitle>
        <Line />
        <SectionDescription width="630px" margin="38px auto 0px auto">
          We working hard to provide the best quality for you and we never
          choose easy and faster ways to quickly break up with your project.
        </SectionDescription>
        <CardsWrapper>
          <CardsItem>
            <LaptopProgrammingIcon className="responsive-icon" />
            <CardsItemTitle>Web application development</CardsItemTitle>
            <CardsItemDescription>
              We&rsquo;re defining the problem, mocking up the solution,
              engaging with users, adopting a framework/choosing a tool, and
              building and testing the web application.
            </CardsItemDescription>
          </CardsItem>
          <CardsItem>
            <LaptopPaintingIcon className="responsive-icon" />
            <CardsItemTitle>Web and Mobile app Design</CardsItemTitle>
            <CardsItemDescription>
              Our designers deeply care about user satisfaction and create the
              user-friendly and clean design and then send it to a development
              team.
            </CardsItemDescription>
          </CardsItem>
          <CardsItem>
            <HandProgrammingIcon className="responsive-icon" />
            <CardsItemTitle>Development & support</CardsItemTitle>
            <CardsItemDescription>
              Supporting on all stages involved in bringing a product from
              concept or idea through market release and beyond
            </CardsItemDescription>
          </CardsItem>
          <CardsItem>
            <GraduationHatIcon className="responsive-icon" />
            <CardsItemTitle>Training / preparation</CardsItemTitle>
            <CardsItemDescription>
              We conduct courses on processes, gain practical experience in
              working on a full-fledged product using modern methodologies and
              tools
            </CardsItemDescription>
          </CardsItem>
        </CardsWrapper>
      </Wrapper>
    </PaddingContainer>
  </StyledSection>
);
