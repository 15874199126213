import React from 'react';
import styled from 'styled-components';

import {
  Column,
  Line,
  ResponsiveFontContainer,
  TextBlock,
  TextTitle,
  TitleContainer,
} from 'theme';

import AngularIcon from 'images/assets/angular.svg';
import DjangoIcon from 'images/assets/django.svg';
import JsIcon from 'images/assets/js.svg';
import PythonIcon from 'images/assets/python.svg';
import ReactIcon from 'images/assets/react-js.svg';

const OurTechnologyContainer = styled.section`
  margin-top: 143px;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;

const IconCard = styled.div`
  background-color: #fff;
  width: 116px;
  height: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 27px 20px rgba(6, 34, 93, 0.03);
  border-radius: 16px;
`;

const IconCardWrapper = styled.div`
  width: 116px;
  color: #c6cbde;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  &:hover {
    cursor: pointer;
    color: #36b59a;
    & span {
      color: #36b59a;
    }
  }
`;

const Caption = styled.span`
  color: #252935;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
`;

const FlexList = styled.div`
  display: flex;
  justify-content: center;
  gap: 103px;
  padding-bottom: 32px;
  padding-top: 48px;
  flex-wrap: wrap;
  @media (max-width: 576px) {
    padding-top: 24px;
    gap: 29px;
  }
`;

const TechnologyIcon: React.FC<{ caption: string; children: JSX.Element }> = ({
  caption,
  children,
}) => (
  <IconCardWrapper>
    <IconCard>{children}</IconCard>
    <Caption>{caption}</Caption>
  </IconCardWrapper>
);

export const OurTechnologiesSection = () => {
  return (
    <OurTechnologyContainer>
      <Column gap={3}>
        <div>
          <TitleContainer id="technology" flexDirection="row">
            <TextTitle color="#252935">Our</TextTitle>
            <TextTitle color="#36B59A">Technologies</TextTitle>
          </TitleContainer>
          <Line />
        </div>
        <ResponsiveFontContainer>
          <TextBlock>Some technologies we&apos;re working with now:</TextBlock>
        </ResponsiveFontContainer>
      </Column>
      <FlexList>
        <TechnologyIcon caption="Javascript">
          <JsIcon />
        </TechnologyIcon>
        <TechnologyIcon caption="Python">
          <PythonIcon />
        </TechnologyIcon>
        <TechnologyIcon caption="Angular">
          <AngularIcon />
        </TechnologyIcon>
        <TechnologyIcon caption="Django">
          <DjangoIcon />
        </TechnologyIcon>
        <TechnologyIcon caption="React">
          <ReactIcon />
        </TechnologyIcon>
      </FlexList>
    </OurTechnologyContainer>
  );
};
